import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css'

const loadGoogleMapsScript = () => {
    return new Promise((resolve, reject) => {
        if (document.getElementById('map')) {
            resolve();
            return;
        }

        console.log(process.env.VUE_APP_GOOGLE_MAPS_API_KEY);

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_MAPS_API_KEY}`;
        script.id = 'google-maps';
        script.async = true;
        script.defer = true;

        script.onload = resolve;
        script.onerror = reject;

        document.head.appendChild(script);
    });
};

loadGoogleMapsScript()
    .then(() => {
        console.log('Google Maps script loaded.');
        // Initialize your map here
        createApp(App).mount('#app')

    })
    .catch(error => {
        console.error('Failed to load Google Maps script:', error);
    });

