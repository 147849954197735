<template>
  <div class="flex flex-col h-screen">
    <PageHeader />
    <div class="flex flex-1 overflow-hidden">
      <SidebarMenu
          @toggle="handleToggle"
          ref="sidebar"
          @quiz-country-name="updateQuizCountryName"
          @update-active-tab="updateActiveTab"
          @quiz-complete="completeQuiz"
          @next-clue="nextClue"
      />
      <div :class="`flex-1 transition-all duration-300 ${sidebarOpen ? 'w-9/12' : 'w-full'}`">
        <GoogleMap
            :activeTab="activeTab"
            :quizCountryName="quizCountryName"
            @update-info="updateInfo"
            @loading="setLoading"
            @quiz-complete="handleQuizComplete"
            @next-clue="nextClue"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import GoogleMap from './components/GoogleMap.vue';
import SidebarMenu from './components/SidebarMenu.vue';

export default {
  name: 'App',
  components: {
    PageHeader,
    GoogleMap,
    SidebarMenu,
  },
  data() {
    return {
      sidebarOpen: true,
      activeTab: 'info',
      quizCountryName: '',
      quizCoordinates: null,
    };
  },
  methods: {
    handleToggle(isOpen) {
      this.sidebarOpen = isOpen;
    },
    updateInfo(data) {
      this.$refs.sidebar.updateInfo(data);
    },
    setLoading(isLoading) {
      this.$refs.sidebar.setLoading(isLoading);
    },
    updateQuizCountryName(name) {
      console.log('updateQuizCountryName(name) in app.vue' + name);
      this.quizCountryName = name;
    },
    updateActiveTab(tab) {
      console.log('updating active tab');
      this.activeTab = tab;
    },
    completeQuiz() {
      console.log('completeQuiz in App.vue');
      this.$refs.sidebar.completeQuiz();
    },
    nextClue() {
      console.log('nextClue in App.vue');
      this.$refs.sidebar.nextClue();
    },
    handleQuizComplete({lat, lng}) {
      this.quizCoordinates = {lat, lng};
      this.$refs.sidebar.completeQuiz();
    }
  }
};
</script>

<style>
/* Ensure body and html take up full height */
html, body, #app {
  height: 100%;
  margin: 0;
}
</style>
