<template>
  <div class="p-4 quiz-section">
    <h2 class="text-xl mb-4">Country Quiz</h2>

    <!-- Continent Selection -->
    <div v-if="!continentSelected" class="mb-4">
      <p>Select a continent to start:</p>
      <div class="flex flex-wrap gap-2">
        <button
            v-for="continent in continents"
            :key="continent"
            @click="selectContinent(continent)"
            class="bg-blue-500 text-white p-2 rounded"
        >
          {{ continent }}
        </button>
      </div>
    </div>

    <!-- Quiz Section -->
    <div v-else>
      <div class="scoreboard mb-4">
        <p><strong>Score:</strong> {{ totalScore }}</p>
        <div class="flex justify-around">
          <p><strong>Diamond:</strong> {{ scoreCounts.diamond }}</p>
          <p><strong>Gold:</strong> {{ scoreCounts.gold }}</p>
          <p><strong>Silver:</strong> {{ scoreCounts.silver }}</p>
          <p><strong>Bronze:</strong> {{ scoreCounts.bronze }}</p>
        </div>
      </div>
      <div v-if="loading">Loading...</div>
      <div v-else>
        <!-- Quiz Content -->
        <div v-if="currentClueIndex < quizData.quiz_clues.length && !quizCompleted" class="mb-2 clue-bar">
          <strong>Clue {{ currentClueIndex + 1 }}:</strong>
          <p>{{ quizData.quiz_clues[currentClueIndex] }}</p>
          <button @click="nextClue" class="mt-4 bg-blue-500 text-white p-2 rounded">Next</button>
        </div>

        <!-- Quiz Completion -->
        <div v-else-if="quizCompleted" class="mt-4">
          <h3 class="text-lg mb-2">Congratulations! You guessed it right.</h3>
          <p><strong>Country Name:</strong> {{ quizData.country_name }}</p>
          <img :src="flagUrl" alt="Country flag" class="mt-2">
          <p><strong>Score:</strong> {{ score }}</p>
          <button @click="restartQuiz" class="mt-4 bg-green-500 text-white p-2 rounded">Next</button>
        </div>
      </div>
    </div>

    <!-- Results Modal -->
    <div v-if="showResultsModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div class="bg-white p-6 rounded-lg max-w-lg w-full shadow-lg">
        <h3 class="text-2xl font-semibold mb-4">Quiz Completed</h3>
        <p>You have gone through all the countries in {{ continentSelected }}!</p>
        <p><strong>Total Score:</strong> {{ totalScore }}</p>
        <div class="flex justify-end mt-4">
          <button
              @click="resetQuiz"
              class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Back to Continent Selection
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CountryQuiz',
  data() {
    return {
      continents: ['Africa', 'Asia', 'Europe', 'America', 'Australia/Oceania'],
      continentSelected: null,
      quizData: {},
      loading: false,
      currentClueIndex: 0,
      shownCountries: [],
      quizCompleted: false,
      score: 0,
      totalScore: 0,
      scoreCounts: {
        diamond: 0,
        gold: 0,
        silver: 0,
        bronze: 0
      },
      showResultsModal: false, // State for results modal
    };
  },
  methods: {
    selectContinent(continent) {
      this.continentSelected = continent;
      this.restartQuiz();
    },
    async fetchQuizData() {
      this.loading = true;
      try {
        const response = await fetch(`${process.env.VUE_APP_API_URL}/api/quiz`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            continent: this.continentSelected,
            exclude: this.shownCountries,
          }),
        });
        if (response.status === 400) {
          // No more countries available
          this.showResultsModal = true;
          this.loading = false;
          return;
        }
        this.quizData = await response.json();
        this.shownCountries.push(this.quizData.country_name);
        this.$emit('quiz-country-name', this.quizData.country_name);
        this.currentClueIndex = 0;
        this.quizCompleted = false;
        this.loading = false;
      } catch (error) {
        console.error('Error fetching quiz data:', error);
        this.loading = false;
      }
    },
    async restartQuiz() {
      this.showResultsModal = false; // Hide modal when restarting
      await this.fetchQuizData();
    },
    resetQuiz() {
      this.continentSelected = null;
      this.shownCountries = [];
      this.quizData = {};
      this.totalScore = 0;
      this.scoreCounts = {
        diamond: 0,
        gold: 0,
        silver: 0,
        bronze: 0,
      };
      this.showResultsModal = false;
    },
    nextClue() {
      if (this.currentClueIndex < this.quizData.quiz_clues.length - 1) {
        this.currentClueIndex += 1;
      } else {
        this.completeQuiz();
      }
    },
    completeQuiz() {
      this.quizCompleted = true;
      this.calculateScore();
    },
    calculateScore() {
      const clueCount = this.currentClueIndex + 1;
      if (clueCount === 1) {
        this.score = 100;
        this.scoreCounts.diamond += 1;
      } else if (clueCount === 2) {
        this.score = 75;
        this.scoreCounts.gold += 1;
      } else if (clueCount === 3) {
        this.score = 50;
        this.scoreCounts.silver += 1;
      } else {
        this.score = 25;
        this.scoreCounts.bronze += 1;
      }
      this.totalScore += this.score;
      this.$emit('update-score', this.score);
    },
  },
  computed: {
    flagUrl() {
      return `https://flagpedia.net/data/flags/w580/${this.quizData.country_code?.toLowerCase()}.webp?v=un`;
    },
  },
};
</script>
