<!-- src/components/Modal.vue -->
<template>
  <div v-if="isOpen" class="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-4 rounded-lg max-w-5xl w-11/12 h-4/5 relative">
      <button @click="closeModal" class="absolute top-2 right-2 text-red-500">
        <i class="fas fa-times"></i>
      </button>
      <iframe :src="url" class="w-full h-full rounded"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalWindow',
  props: {
    url: {
      type: String,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
@import '~@fortawesome/fontawesome-free/css/all.css';
</style>