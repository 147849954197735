<!-- src/components/SidebarMenu.vue -->
<template>
  <div :class="`flex flex-col h-full transition-all duration-300 ${isOpen ? 'w-80' : 'w-16'}`">
    <button @click="toggleSidebar" class="mb-4 p-2 bg-blue-500 text-white flex justify-center">
      <i :class="`fas ${isOpen ? 'fa-chevron-left' : 'fa-bars'}`"></i>
    </button>
    <div v-if="isOpen" class="flex flex-col flex-grow overflow-y-auto">
      <div class="flex justify-around mb-4">
        <button @click="setActiveTab('info')" :class="{'bg-blue-500 text-white': activeTab === 'info'}" class="p-2">Info</button>
<!--        <button @click="setActiveTab('history')" :class="{'bg-blue-500 text-white': activeTab === 'history'}" class="p-2">History</button>-->
        <button @click="setActiveTab('quiz')" :class="{'bg-blue-500 text-white': activeTab === 'quiz'}" class="p-2">Quiz</button>
      </div>
      <div class="flex-grow overflow-y-auto p-4">
        <div v-if="loading" class="flex justify-center items-center h-full">
          <div class="loader"></div>
        </div>
        <div v-else>
          <div v-if="activeTab === 'info' && hasInfoData">
            <InfoTab :infoData="infoData" :flagUrl="flagUrl" @open-modal="openModal"  @close="isModalOpen = false" />
          </div>
        </div>
<!--        <div v-if="activeTab === 'history'">-->
<!--          <h2 class="text-xl mb-4">History</h2>-->
<!--          <ul>-->
<!--            <li class="mb-2">History Item 1</li>-->
<!--            <li class="mb-2">History Item 2</li>-->
<!--            <li class="mb-2">History Item 3</li>-->
<!--          </ul>-->
<!--        </div>-->
        <div v-if="activeTab === 'quiz'">
          <CountryQuiz
             @quiz-country-name="updateQuizCountryName"
             @update-score="updateScore"
             ref="quiz" />
        </div>
      </div>
    </div>
    <ModalWindow :url="modalUrl" :isOpen="isModalOpen" @close="isModalOpen = false" />
    <InfoModalWindow :content="infoData" :isOpen="isModalOpen" @close="isModalOpen = false" />
  </div>
</template>

<script>
import ModalWindow from './ModalWindow.vue';
import CountryQuiz from './CountryQuiz.vue';
import InfoTab from './InfoTab.vue';
import InfoModalWindow from './InfoModalWindow.vue';
export default {
  name: 'SidebarMenu',
  components: {
    ModalWindow,
    CountryQuiz,
    InfoTab,
    InfoModalWindow,
  },
  data() {
    return {
      isOpen: true,
      activeTab: 'info',
      infoData: {},
      countryCode: '',
      isModalOpen: false,
      modalUrl: '',
      loading: false,
      totalScore: 0
    };
  },
  methods: {
    toggleSidebar() {
      this.isOpen = !this.isOpen;
      this.$emit('toggle', this.isOpen);
    },
    updateInfo(data) {
      this.infoData = data;
      this.countryCode = data.country_code.toLowerCase();
      this.activeTab = 'info';
      this.loading = false;
    },
    openModal(url) {
      this.modalUrl = url;
      this.isModalOpen = true;
    },
    setLoading(isLoading) {
      this.loading = isLoading;
    },
    updateQuizCountryName(name) {
      console.log('updateQuizCountryName ' + name);
      this.$emit('quiz-country-name', name);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.$emit('update-active-tab', tab);
    },
    completeQuiz() {
      console.log('completeQuiz in SidebarMenu.vue');
      this.$refs.quiz.completeQuiz();
    },
    updateScore(score) {
      this.totalScore += score;
      console.log('New total score: ', this.totalScore);
    },
    nextClue() {
      console.log('nextClue in SidebarMenu.vue');
      this.$refs.quiz.nextClue();
    }
  },
  computed: {
    flagUrl() {
      return `https://flagpedia.net/data/flags/w580/${this.countryCode}.webp?v=un`;
    },
    hasInfoData() {
      return Object.keys(this.infoData).length > 0;
    }
  }
};
</script>

<style scoped>
@import '~@fortawesome/fontawesome-free/css/all.css';

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
