<!-- src/components/Header.vue -->
<template>
  <header class="bg-blue-500 text-white p-4">
    <h1 class="text-2xl">Captain Leo Maps</h1>
    <p class="text-lg">Maps app for Captain Leo to plan his trips</p>
  </header>
</template>

<script>
export default {
  name: 'PageHeader',
};
</script>

